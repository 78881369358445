<template>
  <v-dialog
    v-model="dialog"
    class="settings-dialog"
    :retain-focus="false"
    persistent
    :no-click-animation="true"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    content-class="hb-settings-fullscreen"
  >
    <v-card class="bg-colo-light b-radius-0">
      <v-app-bar dark fixed :height="$vuetify.breakpoint.mdAndUp ? '64' : ''">
        <!-- turning this off, this button doesn't do anything -->
        <!--
        <v-tooltip bottom open-delay="750" style="background: #334450">
          <template v-slot:activator="{ on }">
            <v-hover v-slot:default="{ hover }">
              <v-btn
                style="width: 40px; height: 40px"
                class="ml-n2"
                :class="{ 'hb-button-icon-hamburger-hover-settings': hover }"
                v-on="on"
                icon
                :ripple="false"
                color="#ffffff"
              >
                <span v-if="showDrawer">
                  <svg
                    width="18"
                    height="12"
                    viewBox="0 0 18 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 1C0 0.447715 0.447716 0 1 0H17C17.5523 0 18 0.447715 18 1C18 1.55228 17.5523 2 17 2H1C0.447715 2 0 1.55228 0 1ZM0 6C0 6.55228 0.447715 7 1 7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H1C0.447715 5 0 5.44772 0 6ZM0 11C0 11.5523 0.447715 12 1 12H17C17.5523 12 18 11.5523 18 11C18 10.4477 17.5523 10 17 10H1C0.447716 10 0 10.4477 0 11Z"
                      fill="#ffffff"
                    />
                  </svg>
                </span>
                <span v-else>
                  <hb-icon color="white" active-color="white"
                    >mdi-arrow-expand-right</hb-icon
                  >
                </span>
              </v-btn>
            </v-hover>
          </template>
          <span v-if="showDrawer">Hide Navigation</span>
          <span v-else>Show Navigation</span>
        </v-tooltip>
        -->

        <v-toolbar-title class="ml-2 mt-n1">{{ appName }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <hb-btn
          icon
          large-close
          class="mr-n2"
          hover-background-color="#333333"
          color="#ffffff"
          @click="closeDialog"
        >
          mdi-close
        </hb-btn>
      </v-app-bar>

      <div style="padding-left: 60px; padding-top: 64px">
        <app-navigation />
        <app-sidebar
          v-if="showSideBar"
          v-show="showDrawer"
          :application="appExtensions"
          :enable-search="enableSearchField"
          @selectPage="componentSelected"
        />
        <v-flex
          class="hb-flex ml-5 pr-6"
          :class="{
            'component-section': hasMultipleExtensions && showDrawer && showSideBar,
          }"
        >
          <div
            v-if="hasCmsAppExtensions"
            class="mb-0 mt-8 hb-header-font-size font-weight-medium"
          >
            {{ componentTitle }}
            <v-divider class="mt-5"></v-divider>
          </div>

          <renderer
            v-if="hasComponent"
            :domain="gdsUrl"
            :apikey="apiKey"
            :appid="appData.appId"
            :owner="company.gds_owner_id"
            :template="renderComponent"
            :applist="appList"
            :apiurl="apiUrl"
            :usertoken="userToken"
          >
          </renderer>
          <template v-else>
            <v-container :key="align" class="lighten-5 mb-6">
              <v-row :align="align" style="height: 250px">
                <v-col v-for="(app, index) in apps" :key="index" cols="3">
                  <v-card class="pa-2" outlined tile>
                    <div class="get-more-apps">
                      <img :src="app.icon" width="50" height="50" />
                      <p style="text-align: center; padding-top: 10px">
                        {{ app.name }}
                      </p>
                      <hb-btn v-if="!app.installed" color="primary"
                        >Install</hb-btn
                      >
                      <hb-btn v-else color="secondary">Installed</hb-btn>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-flex>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import AppSidebar from "../../components/includes/AppSidebar";
import { EventBus } from "../../EventBus";
import renderer from "../../Renderer";
import { mapGetters } from "vuex";
export default {
  name: "AppDialog",
  data() {
    return {
      dialog: true,
      selectedComponent: "",
      showDrawer: true,
      align: "start",
      alignments: ["start", "center", "end"],
      apps: [
        {
          name: "Analytics",
          icon: "https://d1y377jq6m92v5.cloudfront.net/owners/own3f783d2b3ff848fdbb9008f4c2deb95f/website/homepage/analytics.png",
          installed: false,
        },
        {
          name: "Certification Call Grading",
          icon: "https://d1y377jq6m92v5.cloudfront.net/owners/own3f783d2b3ff848fdbb9008f4c2deb95f/website/homepage/callgrading.png",
          installed: true,
        },
        {
          name: "CCTV",
          icon: "https://d1y377jq6m92v5.cloudfront.net/owners/own3f783d2b3ff848fdbb9008f4c2deb95f/website/homepage/cctv.png",
          installed: false,
        },
        {
          name: "Facility Competition Monitor",
          icon: "https://d1y377jq6m92v5.cloudfront.net/owners/own3f783d2b3ff848fdbb9008f4c2deb95f/website/homepage/competitionmonitor.png",
          installed: true,
        },
        {
          name: "Document Center",
          icon: "https://d1y377jq6m92v5.cloudfront.net/owners/own3f783d2b3ff848fdbb9008f4c2deb95f/website/homepage/documents.png",
          installed: false,
        },
        {
          name: "Gate Access",
          icon: "https://dr2r4w0s7b8qm.cloudfront.net/gds_app/gateaccess.png",
          installed: false,
        },
        {
          name: "Insurance Claims",
          icon: "https://d1y377jq6m92v5.cloudfront.net/owners/own3f783d2b3ff848fdbb9008f4c2deb95f/website/homepage/insurance1.png",
          installed: true,
        },
        {
          name: "Merchandise",
          icon: "https://d1y377jq6m92v5.cloudfront.net/owners/own3f783d2b3ff848fdbb9008f4c2deb95f/website/homepage/merchandise.png",
          installed: false,
        },
        {
          name: "Reviews",
          icon: "https://d1y377jq6m92v5.cloudfront.net/owners/own3f783d2b3ff848fdbb9008f4c2deb95f/website/homepage/reviews.png",
          installed: false,
        },
        {
          name: "Revenue Management",
          icon: "https://d1y377jq6m92v5.cloudfront.net/owners/own3f783d2b3ff848fdbb9008f4c2deb95f/website/homepage/revman.png",
          installed: true,
        },
        {
          name: "Wayfinder",
          icon: "https://d1y377jq6m92v5.cloudfront.net/owners/own3f783d2b3ff848fdbb9008f4c2deb95f/website/homepage/wayfinder.png",
          installed: true,
        },
      ],
    };
  },
  props: {
    appData: {
      type: Object,
      default: () => ({}),
    },
    gdsUrl: {
      type: String,
      default: "",
    },
    apiKey: {
      type: String,
      default: "",
    },
  },
  components: {
    AppSidebar,
    "app-navigation": () =>
      import("../includes/AppNavigation/PlatformNavigation"),
  },
  computed: {
    userToken() {
      return localStorage.getItem('auth_token') ?? ''
    },
    ...mapGetters({
      cmsAppId: "authenticationStore/getCmsAppId",
      platformAppToken: "authenticationStore/getPlatformAppToken",
      companyDetails: "authenticationStore/getCompany",
      appIdList: "authenticationStore/getApplications",
      company: "authenticationStore/getCompany"
    }),
    appList() {
      return JSON.stringify(this.appIdList);
    },
    apiUrl() {
        let url = process.env.VUE_APP_API_PROTOCOL + '://' + process.env.VUE_APP_API_SUBDOMAIN + '.' + process.env.VUE_APP_DOMAIN + ':' + process.env.VUE_APP_API_PORT;
        return url;
    },
    appExtensions() {
      let uniqueExtensions = [];
      let appExtensions = this.appData?.extensions;

      appExtensions?.forEach((extension) => {
        if (extension.type != "app") {
          let hasExtensions = uniqueExtensions.find(
            (ext) =>
              ext?.component === extension?.component &&
              ext?.label.en === extension?.label?.en
          );
          if (!hasExtensions) {
            uniqueExtensions.push(extension);
          }
        }
      });
      console.log(uniqueExtensions);
      return uniqueExtensions;
    },
    appName() {
      return this.appData?.name ?? "App Dialog";
    },
    hasComponent() {
      return this.componentName && this.componentName != "get-more";
    },
    hasCmsAppExtensions() {
      return this.hasMultipleExtensions && this.appData?.id === "cms-app";
    },
    componentName() {
      return this.appData?.component;
    },
    componentTitle() {
      return this.selectedComponent?.label?.en;
    },
    renderComponent() {
      return this.selectedComponent
        ? this.selectedComponent?.component
        : this.componentName;
    },
    hasMultipleExtensions() {
      return this.appExtensions?.length > 1;
    },
    enableSearchField() {
      return !!this.appData?.enableSearchField 
    },
    showSideBar() {
      return  this.hasMultipleExtensions && this.appData?.hasSideBar
    }
  },
  created() {
    this.$store.dispatch("navigationStore/setSettingsNavigationOnly", true);
    EventBus.$on('openAppDialog', this.clearSelectedComponent)
  },
  destroyed() {
    this.$store.dispatch("navigationStore/setSettingsNavigationOnly", false);
    EventBus.$off('openAppDialog', this.clearSelectedComponent)
  },
  methods: {
    clearSelectedComponent() {
      this.selectedComponent = null
    },
    componentSelected(component) {
      this.selectedComponent = component;
    },
    closeDialog() {
      this.dialog = false;
      EventBus.$emit("closeAppDialog");
      let element = document.querySelector('body')
      element.classList.remove('hide-scroll') 
    },
  },
};
</script>
<style lang="scss" scoped>
.component-section {
  padding-left: 205px;
  scroll-behavior: auto;
  padding-top: 0;
  background-color: #fafafa;
}
.get-more-apps {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>