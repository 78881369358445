var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-navigation-drawer',{staticClass:"ml-15 mt-12 pt-6 pb-12 side-bar-nav",attrs:{"width":"200","clipped":"","flat":"","permanent":"","fixed":"","disable-resize-watcher":""}},[_c('div',{staticClass:"filter-section"},[(_vm.filterTags.length)?_c('v-select',{class:_vm.filterClass,attrs:{"items":_vm.filterTags,"menu-props":{ maxHeight: '300' },"placeholder":"Filter","multiple":"","append-icon":"mdi-filter-variant","outlined":"","dense":"","dark":"","item-color":"#00848E"},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('span',[(_vm.filterInput.length === 1)?_c('div',[_vm._v(" "+_vm._s(item)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.substring(0, 5))+" ")])]):_vm._e(),(index === 1)?_c('span',[_vm._v(" ..(+"+_vm._s(_vm.filterInput.length - 1)+" others) ")]):_vm._e()]}}],null,false,2124992718),model:{value:(_vm.filterInput),callback:function ($$v) {_vm.filterInput=$$v},expression:"filterInput"}}):_vm._e(),(_vm.enableSearch)?_c('v-text-field',{staticClass:"placeholder-search",attrs:{"placeholder":"Search","append-icon":"mdi-magnify","outlined":"","dense":"","color":"#00848E"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('div',[_c('v-list',{staticClass:"nav-list mb-12 pb-5 pt-0 pl-0 pr-0",class:{
          'nav-list-filter mb-12 pb-5 pt-0 pl-0 pr-0': _vm.filterInput.length > 1,
        },attrs:{"nav":""}},_vm._l((_vm.navigationItems),function(route,index){return _c('v-list-item',{key:index,staticClass:"mt-0 py-0 mb-0 pl-4 setting-menu-list",class:{
            'active-menu-class ': _vm.componentTitle == route.label.en,
          },on:{"click":function($event){return _vm.selectPage(route)}}},[_c('v-list-item-content',[_c('div',{staticClass:"setting-menu-list-inactive-color font-weight-regular",class:{
                'setting-menu-list-active-color font-weight-medium':
                  _vm.componentTitle == route.label.en,
              },staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(route.label.en)+" ")])])],1)}),1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }