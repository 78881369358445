<template>
  <div>
    <v-navigation-drawer
      class="ml-15 mt-12 pt-6 pb-12 side-bar-nav"
      width="200"
      clipped
      flat
      permanent
      fixed
      disable-resize-watcher
    >
      <div class="filter-section">
        <v-select
          v-if="filterTags.length"
          v-model="filterInput"
          :class="filterClass"
          :items="filterTags"
          :menu-props="{ maxHeight: '300' }"
          placeholder="Filter"
          multiple
          append-icon="mdi-filter-variant"
          outlined
          dense
          dark
          item-color="#00848E"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">
              <div v-if="filterInput.length === 1">
                {{ item }}
              </div>
              <div v-else>
                {{ item.substring(0, 5) }}
              </div>
            </span>
            <span v-if="index === 1">
              ..(+{{ filterInput.length - 1 }}
              others)
            </span>
          </template>
        </v-select>

        <v-text-field
          v-if="enableSearch"
          v-model="search"
          class="placeholder-search"
          placeholder="Search"
          append-icon="mdi-magnify"
          outlined
          dense
          color="#00848E"
        >
        </v-text-field>
      </div>

      <div>
        <v-list
          nav
          class="nav-list mb-12 pb-5 pt-0 pl-0 pr-0"
          :class="{
            'nav-list-filter mb-12 pb-5 pt-0 pl-0 pr-0': filterInput.length > 1,
          }"
        >
          <v-list-item
            v-for="(route, index) in navigationItems"
            :key="index"
            class="mt-0 py-0 mb-0 pl-4 setting-menu-list"
            :class="{
              'active-menu-class ': componentTitle == route.label.en,
            }"
            @click="selectPage(route)"
          >
            <v-list-item-content>
              <div
                class="setting-menu-list-inactive-color font-weight-regular"
                :class="{
                  'setting-menu-list-active-color font-weight-medium':
                    componentTitle == route.label.en,
                }"
                style="font-size: 14px"
              >
                {{ route.label.en }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "SideBarNavigation",
  props: {
    application: {
      type: Array,
    },
    enableSearch: {
      type: Boolean,
      default: false
    }
  },

  computed: {
      filterTags() {
        let tags = []
        this.application.forEach((extension) => {
          if (extension?.tags) {
            tags = [...tags, ...extension?.tags]
            tags = [...new Set(tags)]
          }
        })
        return tags
      },

    navigationItems() {
      let navItems = []
      if (this.searchItems.length) {
        navItems = this.searchItems
      } else if (!this.search.length && !this.filterInput.length) {
        navItems = this.application
      }
      return navItems
    },
    // filter navigation items
    filteredNavigationItems() {
      let filteredItems = JSON.parse(JSON.stringify(this.application))
      if (this.filterInput.length) {
        let searchList = []
        filteredItems.forEach((app) => {
          if (app.tags) {
            let result = this.filterInput.every((val) =>
              app.tags.includes(val.toLowerCase())
            )
            if (result) {
              searchList.push(app)
            }
          }
        })
        filteredItems = searchList
      }
      return filteredItems
    },
    // search navigation items
    searchItems() {
      let searchedItems = []
      searchedItems = this.filteredNavigationItems.filter((app) =>
        app.label.en.toLowerCase().includes(this.search.toLowerCase())
      )
      return searchedItems
    },
    filterClass() {
      return this.filterInput.length
        ? 'filter-after placeholder-filter-after'
        : 'filter placeholder-filter'
    },
  },

  data: () => ({
    componentName: '',
    componentTitle: '',
    search: '',
    filterInput: '',
  }),

  mounted() {
    let componentDetails = Object.values(this.application)?.[0]
    this.selectPage(componentDetails)
  },

  methods: {
    selectPage(component) {
      this.componentName = component?.component
      this.componentTitle = component?.label?.en
      this.$emit('selectPage', component)
    },
  },
}
</script>

<style lang="scss" scoped>
.side-bar-nav {
  .navigation {
    z-index: 0;
    max-height: 80%;
    overflow: auto;
  }
  .filter-section {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #f9fafb;
    width: 100%;
    border-bottom: 1px solid #dfe3e8;
    border-right: 1px solid #dfe3e8;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .filter {
    background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
  }
  .filter-after {
    background: linear-gradient(180deg, #47c0bf -70%, #00848e 126.25%);
  }
  .setting-menu-list-inactive-color {
    color: #637381;
  }
  .setting-menu-list-active-color {
    color: #000;
  }
  .active-menu-class {
    background: #e0f5f5;
  }
}
</style>

<style lang="scss">
.side-bar-nav {
  .v-text-field__details {
    display: none;
  }
  .placeholder-search input::placeholder {
    color: #101318 !important ;
    font-size: 14px;
    line-height: 17px;
    padding-top: 7px;
    padding-bottom: 12px;
  }
  .placeholder-filter input::placeholder {
    color: #101318 !important ;
    font-size: 14px;
    line-height: 17px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .placeholder-search {
    &.v-text-field--outlined fieldset {
      border: none;
    }
  }
  .placeholder-filter {
    &.v-text-field--outlined fieldset {
      border-color: #c4cdd5;
    }
  }
  .placeholder-filter-after {
    &.v-text-field--outlined fieldset {
      border: none;
    }
  }
  .filter .theme--dark.v-icon {
    color: #637381;
  }
  .v-list-item {
    min-height: 40px;
  }
}
</style>